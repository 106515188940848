"use client";
import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import DatePickerInput from "../DatePicker";
import GuestPickerInput from "../GuestPicker";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import LocationInput from "../LocationInput";
import Modal from "../Modal";
import ModalHeader from "../Modal/ModalHeader";
import { SearchLocation } from "@/lib/utils/googleMapsUtils";
import { addDays, differenceInDays, format, isAfter, isBefore, startOfTomorrow } from "date-fns";
import { MAXIMUM_NUMBER_OF_STAY_DAYS, MINIMUM_NUMBER_OF_STAY_DAYS } from "@/lib/constants/datepicker";
import { generateSearchUrl } from "@/lib/utils/search";
import { defaultMithobhai } from "@/lib/types/Search";
import { useRouter, useSearchParams } from "next/navigation";
import { GuestList, SearchUrlProps } from "@/lib/types/Search";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { SEARCH_API_DATE_FORMAT } from "@/lib/constants/search";

export type OnSearchProps = {
    location: SearchLocation;
    checkIn: Date;
    checkOut: Date;
    guests: GuestList;
};

type Props = {
    defaultProps?: SearchUrlProps;
    onSearch?: (searchData: OnSearchProps, carryOverParams?: { [key: string]: string }) => void;
    onCloseMobileModal?: () => void;
    isMobileModalOpen?: boolean;
    className?: string;
    children?: React.ReactNode;
    isLoading?: boolean;
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    isOnResultsPage?: boolean;
};

const SearchBar = ({
    defaultProps,
    onSearch,
    className,
    onCloseMobileModal,
    isMobileModalOpen = false,
    children,
    isLoading = false,
    setIsLoading,
    isOnResultsPage = false,
}: Props) => {
    const lang = useLanguage();
    const router = useRouter();
    const auth = useAtomValue(authAtom);
    const { t: translate } = useTranslation(lang, "search");
    const t = (key: string): string | undefined => translate(key) || undefined;
    const [checkIn, setCheckIn] = useState<Date | undefined>(defaultProps?.startDate);
    const [checkOut, setCheckOut] = useState<Date | undefined>(defaultProps?.endDate);
    const [location, setLocation] = useState<SearchLocation | undefined>(defaultProps?.location);
    const [locationError, setLocationError] = useState<string | null>(null);
    const [internalLoader, setInternalLoader] = useState<boolean>(false);
    const [guestList, setGuestList] = useState<GuestList>({
        guests: defaultProps?.guestCount as number,
    });
    const [minCheckOutMinDate, setMinCheckOutMinDate] = useState<Date>();
    const [checkoutMaxDate, setCheckoutMaxDate] = useState<Date>();
    const locationRef = useRef<HTMLInputElement>(null);
    const checkOutRef = useRef<HTMLInputElement>(null);
    const checkInRef = useRef<HTMLInputElement>(null);
    const guestRef = useRef<HTMLInputElement>(null);
    const intialQuery = useSearchParams();

    const onCheckInChange = (checkIn: Date) => {
        setCheckIn(checkIn);
        const nextMonth = addDays(checkIn, MINIMUM_NUMBER_OF_STAY_DAYS);
        setMinCheckOutMinDate(nextMonth);
        setCheckoutMaxDate(addDays(checkIn, MAXIMUM_NUMBER_OF_STAY_DAYS));
        checkOutRef.current?.click();
        checkOutRef.current?.focus();
        // only change checkout date if we're below min number of stays or above max number of stays
        if (
            checkOut instanceof Date &&
            (isBefore(checkOut, nextMonth) || (checkoutMaxDate && isAfter(checkOut, checkoutMaxDate)))
        ) {
            setCheckOut(nextMonth);
        }
        checkOutRef.current?.blur();
    };

    const onCheckOutChange = (checkOut: Date) => {
        setCheckOut(checkOut);
    };

    const openAndFocusGuestPicker = () => {
        guestRef.current?.click();
        guestRef.current?.focus();
    };

    useEffect(() => {
        if (defaultProps?.location && location !== defaultProps.location) {
            setLocation(defaultProps?.location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultProps]);

    const defaultOnSearch = (searchQuery: OnSearchProps, carryOverParams: { [key: string]: string }) => {
        console.log("yeh startSearch ka button press hoa home page se");
        defaultMithobhai.firsttimeload = false; // search button got pressed from home, now even if this is first time laod to search page, we need url search parameters
        const searchUrl = generateSearchUrl({
            ...carryOverParams,
            language: lang,
            startDate: searchQuery.checkIn,
            endDate: searchQuery.checkOut,
            guestCount: guestList?.guests ? guestList?.guests : 1,
            location: { name: searchQuery.location.name } as SearchLocation,
        });
        router.push(searchUrl);
    };

    const fireGTMclickOnSearchHotels = (
        location: SearchLocation | undefined,
        checkInDefaultDate: Date,
        checkOutDefaultDate: Date,
    ) => {
        try {
            const checkInDate = new Date(checkIn ?? checkInDefaultDate);
            const checkOutDate = new Date(checkOut ?? checkOutDefaultDate);

            const bookingInDays = differenceInDays(checkOutDate, checkInDate);

            const checkInDateFormatted = format(checkInDate, SEARCH_API_DATE_FORMAT);
            const checkOutDateFormatted = format(checkOutDate, SEARCH_API_DATE_FORMAT);

            const currentPage = isOnResultsPage ? "search_from_search_result_page" : "search_from_homepage";
            const guestCount =
                currentPage === "search_from_homepage" ? parseInt(guestRef.current?.value as string) : guestList.guests;

            GoogleTagManagerEvents.clickOnSearchHotels(
                lang,
                currentPage,
                location ? location.name : "",
                bookingInDays,
                isOnResultsPage ? "search_result_page" : "homepage",
                checkInDateFormatted,
                checkOutDateFormatted,
                guestCount,
                auth.isLoggedIn ? auth.userInfo._id : undefined,
            );
        } catch (error) {
            console.log("Tried to trigger GTM event clickOnSearchHotels but failed with error:", error);
        }
    };

    const onSearchClick = () => {
        if (location?.name) {
            if (setIsLoading instanceof Function && !isLoading) {
                setIsLoading(true);
            } else {
                setInternalLoader(true);
            }
            const checkInDefaultDate = startOfTomorrow();
            let checkOutDefaultDate = addDays(checkInDefaultDate, MINIMUM_NUMBER_OF_STAY_DAYS);
            if (checkIn && !checkOut) {
                const checkOutCalc = addDays(checkIn, MINIMUM_NUMBER_OF_STAY_DAYS);
                setCheckOut(checkOutCalc);
                checkOutDefaultDate = checkOutCalc;
            }
            // Carryover of the Ad Reveneu Google and other query string paramas
            const carryOverParams: { [key: string]: string } = {};
            for (const [key, value] of intialQuery.entries()) {
                carryOverParams[key] = value;
            }

            const searchQuery = {
                ...carryOverParams,
                checkIn: checkIn ?? checkInDefaultDate,
                checkOut: checkOut ?? checkOutDefaultDate,
                guests: guestList,
                location: { name: location.name } as SearchLocation,
            } as OnSearchProps;

            fireGTMclickOnSearchHotels(location, checkInDefaultDate, checkOutDefaultDate);

            if (onSearch) {
                onSearch(searchQuery);
            } else {
                defaultOnSearch(searchQuery, carryOverParams);
            }
            if (setIsLoading instanceof Function && isLoading) {
                setIsLoading(false);
            } else {
                setInternalLoader(false);
            }
        } else {
            // we don't need validation here, always open location dropdown
            // setLocationError(t("location-error") as string);
            // console.log("The location field is empty!");
            locationRef.current?.focus();
        }
    };

    const onLocationSelect = (location: SearchLocation) => {
        setLocation(location);
        checkInRef.current?.click();
        checkInRef.current?.focus();
    };

    const onLocationReset = () => {
        setLocation({ name: "" });
    };

    const closeMobileModal = () => {
        if (onCloseMobileModal) {
            onCloseMobileModal();
        }
    };

    return (
        <div className={`${className}`}>
            <div className="hidden xl:flex gap-1.5 items-center justify-center">
                <LocationInput
                    errorMessage={locationError}
                    setErrorMessage={setLocationError}
                    placeholder={t("where-to-go")}
                    type="text"
                    className="basis-4/12"
                    onLocationSelect={onLocationSelect}
                    onLocationReset={onLocationReset}
                    initialValue={location}
                    ref={locationRef}
                />
                <DatePickerInput
                    placeholder={t("check-in")}
                    className="basis-[19%]"
                    onChangeDate={onCheckInChange}
                    initialValue={checkIn}
                    ref={checkInRef}
                />
                <DatePickerInput
                    placeholder={t("check-out")}
                    type="text"
                    className="basis-[19%]"
                    arrowDirection="right"
                    ref={checkOutRef}
                    initialValue={checkOut}
                    onChangeDate={onCheckOutChange}
                    showMonthSelector
                    afterCloseHandler={openAndFocusGuestPicker}
                    minDate={minCheckOutMinDate}
                    maxDate={checkoutMaxDate}
                />
                <GuestPickerInput
                    guestCount={guestList.guests}
                    lang={lang}
                    ref={guestRef}
                    placeholder="Guests"
                    type="text"
                    className="basis-2/12"
                    setGuestCount={(count) => setGuestList({ guests: count } as GuestList)}
                />
                {children}
                <Button
                    disabled={isLoading || internalLoader}
                    isLoading={isLoading || internalLoader}
                    className={isLoading ? "basis-[14%]" : " basis-[12%] "}
                    variant="orange"
                    size="lg"
                    fontSize="base"
                    onClick={onSearchClick}
                >
                    {t("button-title")}
                </Button>
            </div>
            <Modal
                open={isMobileModalOpen}
                setOpen={() => null}
                mode="full-screen"
                onClose={closeMobileModal}
                useDefaultContentPadding
            >
                <div className="flex flex-col h-full">
                    <ModalHeader title={t("button-title") ?? ""} onClose={closeMobileModal} />
                    <div className="flex grow flex-col justify-between">
                        <div className="grid grid-cols-10 x gap-3 px-1.5">
                            <LocationInput
                                errorMessage={locationError}
                                placeholder={t("where-to-go")}
                                type="text"
                                className="col-span-10"
                                onLocationSelect={onLocationSelect}
                                onLocationReset={onLocationReset}
                                initialValue={defaultProps?.location}
                                ref={locationRef}
                            />
                            <DatePickerInput
                                placeholder={t("check-in")}
                                className="col-span-5"
                                isMobileModalOpen={isMobileModalOpen}
                                onChangeDate={onCheckInChange}
                                initialValue={checkIn}
                                ref={checkInRef}
                            />
                            <DatePickerInput
                                placeholder={t("check-out")}
                                type="text"
                                className="col-span-5"
                                isMobileModalOpen={isMobileModalOpen}
                                arrowDirection="right"
                                ref={checkOutRef}
                                onChangeDate={onCheckOutChange}
                                afterCloseHandler={openAndFocusGuestPicker}
                                initialValue={checkOut}
                                showMonthSelector
                                minDate={minCheckOutMinDate}
                                maxDate={checkoutMaxDate}
                            />
                            {children}
                            <GuestPickerInput
                                lang={lang}
                                guestCount={guestList.guests}
                                ref={guestRef}
                                placeholder="Guests"
                                type="text"
                                className="col-span-10"
                                setGuestCount={(count) => setGuestList({ guests: count } as GuestList)}
                            />
                        </div>
                        <div className="grid grid-cols-10">
                            <Button
                                disabled={isLoading}
                                isLoading={isLoading}
                                className="col-span-10"
                                variant="orange"
                                size="lg"
                                fontSize="base"
                                onClick={onSearchClick}
                            >
                                {t("button-title")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SearchBar;
